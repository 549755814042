import React from "react"
//import { Link } from "gatsby"
import Layout from "../components/layout"

const ColorPageTemplate = (props) => {
	const colordata = props.pageContext;
	return (
		<Layout activePath={props.path.substring(1)} pageTitle={colordata.shelf}>
			<div>
				{JSON.stringify(colordata)}
			</div>

		</Layout>
	)
}
export default ColorPageTemplate